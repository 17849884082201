import React, {Component} from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import PokerHeroStyled from './styles/pokerHeroStyled'
import Title from '../components/title'
import FeaturePoker from '../components/featurePoker'
import PokerOverView from '../components/pokerOverView'
import FeaturePokerList from '../components/featurePokerList'
import License from '../components/license'
import TitleMainStyled from './styles/titleMainStyled'


class Poker extends Component {
    render() {
        if(!this.props.data.allContentfulTitle.edges || !this.props.data.allContentfulHero.edges) {
            return <div>Loading...</div>
        }
        const descriptionMeta = this.props.data.allContentfulTitle.edges[0].node.text
        return (
            <Layout>
                <SEO title="Poker" locationPath="poker" description={descriptionMeta} />
                <PokerHeroStyled>
                    <Hero position="Poker"
                        buttonText="Contact us"
                        classButton="pokerCta"
                        bannerImage={true}
                        noBackgroundHero={false}
                        className="pokerContent">
                    </Hero>
                </PokerHeroStyled>
                <TitleMainStyled twoColumns={true}>
                    <div className="container">
                        <Title position="Poker" className="twoColumns" />
                        <div className="featureWrap">
                            <FeaturePoker />
                        </div>
                    </div>
                </TitleMainStyled>
                <PokerOverView />
                <FeaturePokerList />
                <License />
            </Layout>
        )
    }
}

export default Poker

export const pageQuery = graphql`
    query PokerPageQuery {
        allContentfulTitle(filter: {position: {eq: "Poker"}}) {
            edges {
                node {
                    text
                }
            }
        }
        allContentfulHero(filter: {bannerPosition: {eq: "Poker"}}) {
            edges {
                node {
                    bannerImage2 {
                        sizes(maxHeight: 1000, quality: 90, resizingBehavior: PAD) {
                            ...GatsbyContentfulSizes_withWebp
                        }
                        title
                    }
                }
            }
        }
    }
`
