import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import OverViewBlock from './overViewBlock'

const PokerOverView = () => {
    const data = useStaticQuery(graphql`
    query PokerOverViewQuery {
        allContentfulPokerDetails(sort: {fields: orderNumber}) {
            edges {
              node {
                id
                subTitle
                title
                orderNumber
                detail {
                  detail
                }
                image {
                  sizes(maxHeight: 1000, quality: 90, resizingBehavior: SCALE) {
                    ...GatsbyContentfulSizes_withWebp
                  }
                  file {
                    fileName
                    url
                  }
                }
              }
            }
        }
    }
    `)
    if(!data.allContentfulPokerDetails.edges) {
        return <div>Loading...</div>
    } else {
        return data.allContentfulPokerDetails.edges.map((edge, idx) => {
          if(edge.node && edge.node.image && edge.node.detail) {
            const imageLayout = idx % 2 === 0 ? 'left' : 'right'
            return <OverViewBlock node={edge.node}
                                layout={imageLayout}
                                html={edge.node.detail.detail}
                                className='Poker'
                                key={edge.node.id} />
          }
          return true
        })

    }

}
 export default PokerOverView

