import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device';


export const FeatureListPokerWrapStyled = styled.div`
     background-color: ${props => props.blockPos === 'even' ? palette.black : palette.darkBlueGrey};
`;

export const FeatureListPokerStyled = styled.div`
    background-color: ${props => props.blockPos === 'even' ? palette.black : palette.darkBlueGrey};
    padding-top: 7rem;
    padding-left: 15px;
    padding-right: 15px;
    max-width: ${width.lg};
    margin: 0 auto;

    .textWrap {
        margin-bottom: 5rem;
        text-align: center;
    }

    .title {
        font-family: 'bebas-neue', sans-serif;
        font-size: ${palette.fontSizeXl};
        font-weight: ${palette.fontWeightTitle};
        color: ${palette.white};
        text-align: center;

        @media ${device.laptop} {
            text-align: center;
        }
    }

    .text {
            color: ${palette.white};
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            >p:before {
                content: '';
                margin-right: 0;
            }

            >p {
                border: 1px solid white;
                padding: 1rem;
                margin-right: 10px;
                transition: transform .3s ease-out;
            }

            >p:hover {
                transform: scale(1.2);
                background-color: black;
            }
    }

    .imageWrap {
        width: 100%;
        margin: 0 auto;

        .gatsby-image-wrapper {
            width: 100%;
            margin: 0 auto;

            &.featurePoker-1 {
                width: 100%;

                @media ${device.tablet} {
                    width: 60%;
                }
            }

        }
    }
`;