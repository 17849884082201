import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ListWithIcon from './list'
import {FeatureListPokerStyled, FeatureListPokerWrapStyled} from './featureListPokerStyled'



const FeaturePokerList = () => {
    const data = useStaticQuery(graphql`
    query FeaturePokerListQuery {
        allContentfulFeaturePokerList(sort: {fields: orderNumber}) {
            edges {
              node {
                id
                title
                listOverview {
                  listOverview
                }
                image {
                  sizes(maxHeight: 1000, quality: 90, resizingBehavior: PAD) {
                    ...GatsbyContentfulSizes_withWebp
                    base64
                    src
                    srcSet
                  }
                  file {
                    url
                  }
                }
              }
            }
          }
    }
    `)

    if(!data.allContentfulFeaturePokerList.edges) {
        return <div>Loading...</div>
      } else {
        return data.allContentfulFeaturePokerList.edges.map((edge, idx) => {
          if(edge.node && edge.node.image && edge.node.listOverview) {
            const blockPos = idx % 2 === 0 ? 'even' : 'odd'
              return <FeatureListPokerWrapStyled key={edge.node.id} blockPos={blockPos}>
                        <FeatureListPokerStyled blockPos={blockPos}>
                                <div className="textWrap">
                                    <h2 className="title">
                                        {edge.node.title}
                                    </h2>
                                    {/* <div dangerouslySetInnerHTML={{
                                                __html: edge.node.listOverview.listOverview}} className="text">

                                    </div> */}
                                    <ListWithIcon className="PokerList"
                                    html={edge.node.listOverview.listOverview}/>
                                </div>
                                <div className="imageWrap">
                                    <img src={edge.node.image && edge.node.image.file.url} alt={edge.node.title} className={`mainImg featurePoker-${idx}`} />
                                </div>
                            </FeatureListPokerStyled>
                    </FeatureListPokerWrapStyled>
            }
            return true
        })
      }
}

export default FeaturePokerList