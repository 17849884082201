import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import FeatureBlock from './featureBlock'



const FeaturePoker = () => {
    const data = useStaticQuery(graphql`
    query FeaturePokerQuery {
      allContentfulFeaturePoker(sort: {fields: orderNumber}) {
        edges {
          node {
            image {
              sizes(resizingBehavior: PAD, maxHeight: 1000) {
                ...GatsbyContentfulSizes_withWebp
               }
               file {
                url
              }
            }
            title
            text
            id
            orderNumber
          }
        }
      }
    }
    `)

    if(!data.allContentfulFeaturePoker.edges) {
        return <div>Loading...</div>
      } else {
        return data.allContentfulFeaturePoker.edges.map((edge) => {
          if(edge.node && edge.node.image) {
              return <FeatureBlock
                      key={edge.node.id}
                      node={edge.node}
                    />
            }
            return true
        })
      }
}

export default FeaturePoker